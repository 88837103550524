/* GENERAL */

h1 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 5px;
    padding: 50px 0 10px 0;
    font-size: 2.5rem !important;
    font-weight: 500;
    margin: 0;
}

.brand {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 5px;
    padding: 50px 0 0 0;
    font-size: 2.5rem !important;
    font-weight: 500;
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: right;
    margin-top: -10px;
}

.navbar {
    margin-top: 15px;
}

h2 {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-weight: 700 !important;
    font-size: 20px !important;
}

a {
    font-family: 'Alegreya Sans SC', sans-serif;
}

p {
    font-family: 'Alegreya Sans SC', sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.nav-link {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    height: 60px;
    vertical-align: middle;
    display: table-cell !important;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
}

.nav-link:active {
    color: rgba(0, 0, 0, 1);
}

.center {
    text-align: center !important;
    justify-content: center !important;
}

.shop-btn {
    background: #000;
    width: 80px;
    margin-left: 5px;
}

.shop-btn-primary {
    background: url("../images/shop.png") no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 80px;
}

.shop-btn-primary span {
    color: #fff;
}

.navbar {
    flex-direction: column !important;
}

.cart-img {
    width: 30px;
}


/* HEADER */

.name-divider {
    width: 100%;
    opacity: 0.5;
}

.hr {
    margin-top: 1rem;
    margin-bottom: 0 !important;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}


/* PAGE */

.page {
    margin: 20px 15% 10px 15%;
}


/* HOME */

.accent {
    color: #D25200;
}

.main-image {
    width: 400px;
}

.special-desktop {
    display: block;
}

.special-mobile {
    display: none;
}

.main-image {
    width: 400px;
}

.divider {
    width: 50vw;
    height: 1px;
    background-color: black;
    opacity: 0.2;
}

.text-block {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 20px;
}

.text-block p {
    font-weight: 400;
    margin: 40px 0;
    font-size: 20px;
    font-family: 'Alegreya Sans SC', sans-serif;
}

.text-block h2 {
    font-weight: 700;
    margin: 40px 0;
    font-size: 20px;
}

.text-block h3 {
    font-weight: 800;
    margin: 40px 0;
    letter-spacing: 3px;
    font-size: 20px;
}

.block-list {
    list-style: none;
    padding: 0;
    margin: 40px 0;
}

.block-list li {
    margin: 12px 0;
}


/* ABOUT */

.indent {
    list-style: none;
    font-family: 'Alegreya Sans SC', sans-serif;
    margin-right: 40px;
}

.articles {
    margin: 40px 0;
    line-height: 25px;
}


/* CONTACT */

.contact-image {
    width: 400px;
}

.contact-body {
    text-align: center;
    background-color: #fff;
    padding: 70px;
    text-align: center;
    font-size: 20px;
}

.contact-body a {
    text-decoration: underline;
}


/* FOOTER */

.footer {
    text-align: center;
    padding: 40px 0;
}

.social-icon {
    width: 40px;
    margin: 10px;
}


/* SHOP PAGE */

.shop-page {
    margin: 50px 0;
}

.info-col {
    margin-top: 30px;
}

.descriptions {
    margin-top: 30px;
}

.product-img {
    width: 100%;
}

.product-title {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #2A2A2A;
    margin: 5px 0;
    text-transform: uppercase;
}

.s1 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #2A2A2A;
    margin: 1px 0;
    line-height: 25px;
}

.price {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A;
    margin: 10px 0;
}

.qty {
    border: 2px solid #000;
    width: 115px;
    height: 40px;
    margin: 15px 0;
}

.buy-btn {
    margin: 10px 0;
    background: #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 100px;
    border: none;
}

.s2 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2A2A2A;
}

.p-custom {
    margin-top: -5px;
}

.size {
    line-height: 3px;
}

.product-info {
    padding: 5px 0;
}