@media only screen and (max-width: 600px) {
    /* GENERAL */
    .navbar-brand {
        margin-right: 0 !important;
    }
    .brand {
        letter-spacing: 2px;
        font-size: 20px !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: center;
    }
    .subtitle {
        margin-top: -5px;
    }
    .nav-center {
        text-align: center !important;
        justify-content: center !important;
        display: flex !important;
    }
    .nav-link {
        height: 40px;
    }
    .toggle {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        color: #000 !important;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 16px !important;
    }
    .shop-btn-primary {
        background-size: contain;
    }
    .page {
        margin: 20px 20px 10px 20px;
    }
    .mobile-left {
        text-align: left !important;
        justify-content: left !important;
        padding-left: 20px;
    }
    .navbar {
        margin-top: 10px;
    }
    .navbar-toggler {
        border: none !important;
    }
    /* HOME */
    .special-desktop {
        display: none;
    }
    .special-mobile {
        display: block;
    }
    .main-image {
        width: 200px;
    }
    .text-block {
        font-family: 'Alegreya Sans SC', sans-serif;
        font-size: 20px;
    }
    .text-block p {
        font-weight: 400;
        margin: 40px 0;
        font-size: 20px;
        font-family: 'Alegreya Sans SC', sans-serif;
    }
    .text-block h2 {
        font-weight: 700;
        margin: 40px 0;
        font-size: 20px;
    }
    .text-block h3 {
        font-weight: 800;
        margin: 40px 0;
        letter-spacing: 3px;
        font-size: 20px;
    }
    /* CONTACT */
    .contact-body {
        text-align: center;
        background-color: #fff;
        padding: 0px;
        text-align: center;
        font-size: 20px;
    }
    .contact-image {
        width: 100%;
    }
    /* Shop */
}